import axios from './https'
// 获取所有商品列表
export function getAllGoodsList(params) {
  return axios.post('/api/business/goods/all', params)
}

// 获取推荐商品信息
export function getRecommendGoodsList() {
  return axios.get('/api/business/goods/recommend')
}

// 获取商品分类菜单数据
export function getCategoryMenu(params) {
  return axios.get('/api/business/category/menu', { params })
}

// 获取指定分类商品数据
export function getCategoryGoods(params) {
  return axios.post('/api/business/goods/category', params)
}

// 获取商品详情
export function getGoodsDetail(params) {
  return axios.get('/api/business/goods/details', { params })
}

// 获取艺术家数据
export function getArtistList(params) {
  return axios.get('/api/artist/list4Index/' + params)
}

// 获取艺术家详情数据
export function getArtistDetail(params) {
  return axios.get('/api/artist/detail/' + params)
}

// 获取艺术家分页数据
export function getArtistPageList(params) {
  return axios.post('/api/artist/list', params)
}

// 提交订单
export function closeAccount(params) {
  return axios.post('/api/order/submit', params)
}
// 判断微信支付是否完成
export function paymentStateApi(params) {
  return axios.get('/api/wx/pay/trade/query', { params })
}
// 登录 获取图片验证码
export function getPicVerification() {
  return axios.post('/api/auth/getPicVerifyCode')
}

// 登录 获取短信验证码
export function getNoteVerification(params) {
  return axios.post('/api/auth/sendVerifyCode', params)
}

// 登录
export function login(params) {
  return axios.post('/api/auth/login', params)
}

// 退出
export function logout() {
  return axios.get('/api/auth/logout')
}

// 获取地理地址
export function getGeography() {
  return axios.get('/api/adress/amap')
}

// 新增收货地址
export function addAddress(params) {
  return axios.post('/api/adress/add', params)
}

// 修改收货地址
export function editAddress(params) {
  return axios.post('/api/adress/update', params)
}

// 删除收货地址
export function deleteAddress(params) {
  return axios.get('/api/adress/delete', { params })
}

// 获取收获地址列表
export function getAddressList(params) {
  return axios.get('/api/adress/list', { params: params })
}

// 获取购物车列表
export function getGoodsList(params) {
  // console.log(params);
  return axios.get('/api/cart/list', { params })
}

// 添加商品进购物车
export function addShopCar(params) {
  return axios.post('/api/cart/add', params)
}

// 删除购物车列表
export function deleteShopCar(params) {
  return axios.post('/api/cart/delete', params)
}

// 添加收藏
export function addFavorite(params) {
  return axios.post('/api/favorite/add', params)
}

// 取消收藏
export function cancelFavorite(params) {
  return axios.post('/api/favorite/cancel', params)
}

// 批量取消收藏
export function cancelBatchFavorite(params) {
  return axios.post('/api/favorite/batchCancel', params)
}

// 全部收藏
export function allFavorite(params) {
  return axios.get('/api/favorite/myFavorite/' + params)
}

// 是否收藏了某商品
export function isFavorite(params) {
  return axios.post('/api/favorite/isFavorite', params)
}

// 确认订单信息
export function confirmOrder(params) {
  return axios.post('/api/order/confirm', params)
}

// 全部订单信息
// export function allOrder(params){
//     return axios.get("/api/order/all",{params});
// }

// 获取分类订单信息
export function getCatagoryOrder(params) {
  return axios.post('/api/order/category/info', params)
}

// 获取未支付商品订单详情
export function getNotPayDetail(params) {
  return axios.post('/api/order/notPay/info', params)
}

// 获取已支付商品订单详情
export function getPayDetail(params) {
  return axios.post('/api/order/detail', params)
}

// 支付订单
export function getOrderPay(params) {
  return axios.post('/api/order/pay', params)
}

// 取消订单
export function getOrderClose(params) {
  return axios.post('/api/order/trade/close', params)
}

// 增加发票
export function addInvoice(params) {
  return axios.post('/api/invoice/add', params)
}

// 全局搜索
export function searchHandle(params) {
  return axios.post('/api/carouse/carouse4Index', params)
}

// 首页轮播图
export function swiperData() {
  return axios.post('/api/carouse/carouse4Index')
}

// 兑换优惠券
export function couponHandle(params) {
  return axios.post('/api/coupon/exchange', params)
}

// 查询优惠券
export function myCouponHandle(params) {
  return axios.post('/api/coupon/myCoupon', params)
}

// 关键字查询艺术家和商品
export function searchArtist(params) {
  return axios.post('/api/search/artistAndProduct', params)
}

// 获取全部艺术家id
export function allArtistId() {
  return axios.get('/api/artist/list/id')
}

// 商品详情页推荐
export function spuBySpuld(params) {
  return axios.post('/api/recommend/fetchRecommendSpuBySpuId', params)
}

// 获取用户信息
export function getUserInfo(params) {
  return axios.get('/api/user/detail/' + params.userId)
}

// 修改用户信息
export function revampUserInfo(params) {
  return axios.post('/api/user/profileSetting', params)
}

// 实名认证
export function authentication(params) {
  return axios.post('/api/user/verify', params)
}
