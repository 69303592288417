import axios from 'axios'
import { cookieMethods } from '@/util/js/methods.js'
import router from '../../router'
import { encrypt, decrypt } from '@/util/js/crypto'
// import { loadLocaleMessages } from '@/i18n/i18n'

const instance = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    token: cookieMethods.get('token') ? cookieMethods.get('token') : '',
  },
})
instance.interceptors.request.use(
  (config) => {
    if (
      config.method == 'post' &&
      config.url.indexOf('upload/qiniu/avatar') <= 0
    ) {
      config.data = encrypt(JSON.stringify(config.data))
    }

    config.headers.token = cookieMethods.get('token')
      ? cookieMethods.get('token')
      : ''
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)
// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      response.request['responseURL'].indexOf('upload/qiniu/avatar') < 0
    ) {
      const decry = decrypt(response.data)
      response.data = JSON.parse(decry)
      // console.log('response.data==：', response.data)
    }
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === 200) {
      // console.log(typeof response.data == "string");
      if (response.data && response.data['code'] == 50001) {
        // if(typeof response.data == "string"){
        cookieMethods.remove('token')
        router.apps[0].$router.history.push({
          path: '/login',
          query: {
            path: router.apps[0].$route.fullPath,
          },
        })
        return
      }
      // if (response.data.data && response.data.data.transferList) {
      //   loadLocaleMessages(response.data.data.transferList)
      // }
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  (error) => {
    if (
      error.code === 'ECONNABORTED' ||
      error.message === 'Network Error' ||
      error.message.includes('timeout')
    ) {
      // console.log(error.config.__retryCount);
      // console.log(error.config.__retryCount > 2);
      return Promise.reject({
        message: '请求超时,请稍后重试',
      })
      // if (error.config.__retryCount > 2) {
      //     return Promise.reject({
      //         message: "请求超时,请稍后重试"
      //     })
      // } else {
      //     console.log(error.config);
      //     error.config.__retryCount = error.config.__retryCount | 0;
      //     error.config.__retryCount++;
      //     return instance({...error.config, __retryCount: error.config.__retryCount })
      // }
    }
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          this.$router.replace({
            path: '/login',
            query: {
              redirect: this.$router.currentRoute.fullPath,
            },
          })
          break

        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          this.$toast({
            message: '登录过期，请重新登录',
            duration: 1000,
            forbidClick: true,
          })
          // 清除token
          localStorage.removeItem('token')
          this.store.commit('loginSuccess', null)
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {
            this.$router.replace({
              path: '/login',
              query: {
                redirect: this.$router.currentRoute.fullPath,
              },
            })
          }, 1000)
          break

        // 404请求不存在
        case 404:
          this.$toast({
            message: '网络请求不存在',
            duration: 1500,
            forbidClick: true,
          })
          break
        // 其他错误，直接抛出错误提示
        default:
          this.$message({
            message: error.response.data.message,
            duration: 1500,
            forbidClick: true,
          })
      }
      return Promise.reject(error.response)
    }
  }
)

export default instance
