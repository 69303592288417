import CryptoJS from 'crypto-js'

const aesKey = 'kfl.olfjsbcz.com'

export function encrypt(word, keyStr) {
  // word, keyStr第一个参数是加密的字段名字  第二个是key值（16位）
  keyStr = keyStr || aesKey // 密文（密钥）
  var key = CryptoJS.enc.Utf8.parse(keyStr)
  var srcs = CryptoJS.enc.Utf8.parse(word)
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.toString()
}

// 补码方式为PKCS5Padding（也就是PKCS7）
export function decrypt(word, keyStr) {
  keyStr = keyStr || aesKey
  var key = CryptoJS.enc.Utf8.parse(keyStr)
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}
